
.slick-dotted.slick-slider {
 margin-bottom: 0;
}

.slick-dots {
	position: absolute;
 	bottom: 40px;
 	display: flex;
 	list-style: none;
}

.slick-dots > :not([hidden]) ~ :not([hidden]) {
 --tw-space-x-reverse: 0;
 margin-right: calc(20px * var(--tw-space-x-reverse));
 margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
}

.slick-dots {
 padding-left: 20px;
 text-align: left;
}
@media (min-width: 768px) {
	.slick-dots {
  margin-left: calc(100%/12);
  padding-left: 104px;
 }
}

.slick-dots li button {
	font-size: 0;
	line-height: 0;
 height: 24px;
 width: 24px;
 border-radius: 9999px;
 border-width: 2px;
 border-style: solid;
 --tw-border-opacity: 1;
 border-color: rgb(255 255 255 / var(--tw-border-opacity));
 background-color: transparent;
 opacity: 1;
}

.slick-dots li button:before  {
	content: none;
}

.slick-dots li:first-child {
 margin-left: 0;
}

.slick-dots li.slick-active button {
 --tw-border-opacity: 1;
 border-color: rgb(2 115 207 / var(--tw-border-opacity));
 --tw-bg-opacity: 1;
 background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}