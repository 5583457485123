.footer {
	background-color: #000;
	color: #fff;

	ul {
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
	}

	h3 {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
	}
}

.footer-container {
    padding: 0 $grid-gutter-width;
    max-width: map-get($grid-breakpoints, xl);
}

.footer-block {
	padding-top: 40px;
	padding-bottom: 40px;	
	@media (min-width: 1024px){
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.footer-links {
	margin-top: 20px;
	@media (min-width: 768px){
		margin-top: 0;
		padding-right: 12px;

		.collapse-toggler:after {
			content: none;
		}

		.collapse {
			display: block;
		}
	}

	li {
		margin-top: 16px;
		a {
		    color: #41a1ed;
		    font-style: normal;
		    font-weight: 400;
		    line-height: 20px;
		}
	}
}

.footer-social-links {
	margin-top: 40px;
	@media (min-width: 768px){
		display: flex;
		justify-content: center;
		margin-top: 0;
	}
	@media (min-width: 1024px){
		justify-content: flex-end;
	}
	li {
	    color: $gray-base-70;
	    font-size: 14px;
	    font-style: normal;
	    font-weight: 400;
	    line-height: 20px;
		@media (min-width: 768px){
			&:not(:first-child) {
				margin-top: 16px;
			}
		}

	    a {
	    	color: inherit;
	    }

	    svg {
	    	vertical-align: middle;
	    }
	}
}

.footer-small-text {
    font-size: 16px;
    line-height: 24px;
	text-align: center;
	@include media-breakpoint-up(md) {
		text-align: left;
	}
}

.footer-copyrights {
	display: flex;
	justify-content: center;
	height: 36px;
    padding: 9px;
    font-size: 12px;
    line-height: 18px;
    background-color: $gray-base-20;
    color: $gray-base-70;

    a {
    	color: inherit;
    }
}
