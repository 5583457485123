@use "sass:map";

@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;700&display=swap');

@import "mixins/functions";
@import "variables";

$grid-breakpoints: map.merge($grid-breakpoints, (
    tablet: 768px,
    large: 1200px)
);

@import "reboot";
// @import "mixins/colors";
@import "mixins/common";
@import "mixins/grid";
// @import "mixins/i-grid";
// @import "mixins/reset-text";
// @import "mixins/tables";
@import "layout";
@import "mixins/typo";
@import "mixins/hover";

@import "utilities/display";
// @import "utilities/flex";
// @import "utilities/icons";
// @import "utilities/icons-home";
// @import "utilities/flags";
// @import "utilities/spacing";
// @import "utilities/border";
// @import "utilities/position";

// @import "components/loader";
// @import "components/page-message";

// @import "components/icons";
// @import "components/text-clipped";
// @import "components/buttons-group";
@import "components/forms";
// @import "components/custom-forms";
// @import "components/form-range";
@import "components/dropdown";
// @import "components/input-group";
// @import "components/popover";
// @import "components/tooltip";
// @import "components/cards";
// @import "components/tables";
// @import "components/quad-table";
// @import "components/table-dynamic";
// @import "components/company-financials-table";
// @import "components/modal";
@import "components/collapse";
// @import "components/datepicker";
// @import "components/daterange";
// @import "components/images";
// @import "components/field-builder";
// @import "components/options-list";
// @import "components/flat-list";
// @import "components/cat-list";
// @import "components/searchbox";
// @import "components/plates";
// @import "components/linked-list";
// @import "components/rating";
// @import "components/thickness";
// @import "components/colorpicker";
// @import "components/pagination";
// @import "components/badges";
// @import "components/screener-slider";
// @import "components/toasts";
// @import "components/circle-stats";
// @import "components/chart-zoom-controls";
// @import "components/quill";
// @import "components/pill";

// @import "modules/panel-landing";
// @import "modules/reports";
// @import "modules/template-library";
// @import "modules/portfolio";
// @import "modules/preview";
// @import "modules/public";
// @import "modules/public-list";
// @import "modules/key-stats";
// @import "modules/flows";
// @import "modules/panel";
// @import "modules/panels-row";
// @import "modules/panel-sidebar";
@import "home/hero";
@import "home/header";
@import "home/footer";
// @import "modules/nav";
// @import "modules/quote-header";
// @import "modules/chart";
// @import "modules/scatter-plot";
// @import "modules/chart-progress";
// @import "modules/chart-vertical-bars";
// @import "modules/microchart";
// @import "modules/page-header";
// @import "modules/score-manager";
// @import "modules/news";
// @import "modules/notes";
// @import "modules/quickflows";
// @import "modules/notifications";
// @import "modules/toolbar";
// @import "modules/stocks";
// @import "modules/alerts";
// @import "modules/pdf-thumb";
// @import "modules/pdf-widget";
// @import "modules/pdf-preview";
// @import "modules/matrix";
// @import "modules/ai";

// @import "pages/error";
// @import "pages/auth";
// @import "pages/search-all";
// @import "pages/support";
// @import "pages/esg";
// @import "pages/file-manager";
// @import "pages/tiers";


@import "home/typo";
@import "home/cards";
@import "home/buttons";
@import "home/slick";
@import "home/gradients";
@import "home/base";


img {
    max-width: 100%;
}

// .user-select-none {
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -khtml-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }

// .user-content {
//     p {
//         display: inline;
//         margin-bottom: 0;
//         &:after, &:before {
//             content: " \A";
//             white-space: pre;
//         }
//     }

//     p + p:before {
//         content: none;
//     }
// }

// // reset text styles
// .text-default {
//     @include textDefault();
//     text-transform: none;
// }

// .text-smallest {
//     @include chartAxis();
// }

// .text-small {
//     @include textSmall();
// }

// .text-thin {
//     @include smallCapsLabel();
// }

// .text-primary,
// .text-blue {
//     color: $yc-blue;
// }

// .text-red {
//     color: map-get($colors, red);
// }

// .text-green {
//     color: map-get($colors, green);
// }

// .text-black {
//     color: $gray-base-10;
// }

// .text-gold {
//     color: #e4a70d;
// }

// .text-empty {
//     color: $gray-base-60;
// }

// .text-right {
//     text-align: right;
// }

// .text-left {
//     text-align: left;
// }

// .text-center {
//     text-align: center;
// }

// .text-bold {
//     font-weight: 700;
// }

// .text-normal {
//     font-weight: 400;
// }

// .text-nowrap {
//     white-space: nowrap;
// }

// .text-prewrap {
//     white-space: pre-wrap;
// }

// .text-underline {
//     text-decoration: underline;
// }

// .text-underline-hover {
//     &:hover,
//     &:active {
//         text-decoration: underline;
//         cursor: pointer;
//     }
// }

// .text-uppercase {
//     text-transform: uppercase;
// }

// .text-capitalize {
//     text-transform: capitalize;
// }

// .highlight-blue {
//     background-color: $yc-blue-90;
// }

// .divider-vertical {
//     position: relative;
//     width: 0;
//     border-right: 1px solid $gray-base-90;
// }

// .divider-vertical-md {
//     @include media-breakpoint-up(md) {
//         width: 0;
//         border-right: 1px solid $gray-base-90;
//     }
// }

// .divider-horizontal {
//     position: relative;
//     width: 100%;
//     height: 0;
//     border: 0;
//     border-bottom: 1px solid $gray-base-90;
// }

// .img-responsive,
// .img-fluid {
//     display: block;
//     max-width: 100%;
//     height: auto;
// }

// .video-responsive {
//     position: relative;
//     height: 0;
//     padding-bottom: 56.25%;
//     display: block;

//     iframe {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100% !important;
//         height: 100% !important;
//     }
// }

// .readmore {
//     font-weight: 700;
//     color: $yc-blue;
//     text-decoration: none !important;
//     cursor: pointer;
// }

// .help-toggler {
//     @include underline();
//     font-weight: 400;
//     cursor: pointer;
// }

// .panel-subtitle-icon {
//     margin-right: 6px;
//     font-size: 15px;
// }

// .get-started {
//     @extend .border-get-started;
//     @include emptyState();
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     width: 100%;
//     padding-left: 70px;
//     padding-right: 70px;

//     .panel-sidebar & {
//         padding: 30px 40px;
//     }
// }

// .get-started-small {
//     @include headerDefault();
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     padding-top: 30px;
//     padding-bottom: 30px;
//     width: 100%;
//     color: $gray-base-40;
// }

// .get-started-panel {
//     @extend .border-get-started;
//     @include headerMedium();
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     width: 100%;
//     padding-left: 70px;
//     padding-right: 70px;
//     border-top-width: 6px;
//     border-top-style: solid;
//     color: $gray-base-30;
// }

// .get-started-sidebar {
//     padding: $spacer * 2;
//     background-color: $panel-background;
//     text-align: center;

//     .get-started-wrap {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         height: 66.6%;
//     }

//     .get-started-title {
//         @include h3();
//         margin-bottom: $spacer;
//         color: $gray-base-60;
//     }

//     .get-started-text {
//         @include headerDefault();
//         color: $gray-base-60;
//     }
// }

// .columns-sm-2 {
//     @include media-breakpoint-down(sm) {
//         column-count: 2;
//         column-gap: 20px;
//     }
// }

// .base-line-height {
//     @include base-line-height();
// }

// .align-middle {
//     vertical-align: middle;
// }

// .overflow-auto {
//     overflow: auto;
// }

.overflow-hidden {
    overflow: hidden;
}

// .pointer-events-none {
//     pointer-events: none;
// }

// .cursor-pointer {
//     cursor: pointer;
// }

// .cursor-move {
//     cursor: move;
// }

// .chart-fullscreen {
//     position: absolute;
//     background-color: #FFF;
//     top:0;
//     left:0;
//     right:0;
//     bottom: 0;
//     z-index: 11;
// }

// hr.hr {
//     border: 0;
//     border-top: 1px solid $gray-base-90;
// }

.text-white {
    color: #fff;
}

.bg-white {
    background-color: #fff;
}

.text-orange {
    color: $home-orange;
}

.bg-orange {
    background-color: $home-orange;
}

.text-orange-light {
    color: $home-orange-light;
}

.bg-orange-light {
    background-color: $home-orange-light;
}

.text-green {
    color: $home-green;
}

.bg-green {
    background-color: $home-green;
}

.text-green-light {
    color: $home-green-light;
}

.bg-green-light {
    background-color: $home-green-light;
}

.text-purple {
    color: $home-purple;
}

.bg-purple {
    background-color: $home-purple;
}

.text-purple-light {
    color: $home-purple-light;
}

.bg-purple-light {
    background-color: $home-purple-light;
}

// overwrite reboot styles
button:focus {
    outline: none;
}