.y-btn {
    display: inline-flex;
    min-height: 52px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    cursor: pointer;
    border-radius: 0;
    border: 0;
    transition: $home-transition;
    @include media-breakpoint-up(md) {
        min-height: 60px;
    }

    .icon {
        font-size: 24px;
        line-height: 1;

        svg {
            display: block;
        }
    }
}

.y-btn-primary,
a.y-btn-primary {
    background-color: $yc-blue-ada;
    color: #fff;
    &:hover {
        background-color: $yc-blue;
    }
    &:focus {
        background-color: $yc-blue-800;
    }
    &:active {
        background-color: $yc-blue-dark;
    }
    &:disabled {
        background-color: $yc-blue-75;
    }
}

.y-btn-white {
    background-color: #fff;
    color: $yc-blue-ada;
    &:hover,
    &:focus,
    &:active {
        background-color: $yc-blue-95;
    }
    &:disabled {
        color: $gray-base-80;
    }
}

.y-btn-white-outline {
    border: 2px solid $yc-blue-ada;
    background-color: #fff;
    color: $yc-blue-ada;
    &:hover,
    &:focus,
    &:active {
        background-color: $yc-blue-95;
    }
    &:disabled {
        background-color: $yc-blue-95;
        background-color: #fff;
        color: $yc-blue-95;
    }
}

.y-btn-link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: $yc-blue-ada;
    transition: $home-transition;
    &:hover {
        color: $yc-blue;
    }
    &:focus {
        color: $yc-blue-800;
    }
    &:active {
        color: $yc-blue-dark;
    }
    &:disabled {
        color: $gray-base-80;
    }

    .icon {
        font-size: 18px;

        svg {
            vertical-align: middle;
        }
    }
}
