
.section-hero {
	height: calc(100vh - $header-home-height-mobile);
	padding-top: 48px;
	background-color: #fff;
	background-image: url(../../images/home/home-hero-bg.jpg);
	background-position: 20% center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	@media (orientation: portrait) {
		max-height: 800px;
		min-height: auto;
	}
	@media (orientation: landscape) {
		min-height: 720px;
	}
	@include media-breakpoint-up(tablet) {
		height: calc(100vh - $header-home-height);
		min-height: 750px;
		padding-bottom: 24px;
		background-position: center;
	}
	@include media-breakpoint-up(large) {
		padding-top: 56px;
	}
	@media (min-width: map-get($grid-breakpoints, lg)) and (min-height: calc(912px + $header-home-height)) {
		max-height: 912px;
	}
}

.hero-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.hero-text {
	flex: none;
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
	transform: translate(0, -10px);
	// transition: $hero-device-delay ease transform;
	animation: heroTextAnimation $hero-device-delay 1 ease-out forwards;

	&.is-loaded {
		transform: translate(0, 0);
	}
	@media (min-width: 375px) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

.hero-heading {
	padding-bottom: 24px;
	font-size: 28px;
	font-weight: 700;
	line-height: 100%;
	letter-spacing: -.02em;
	@include media-breakpoint-up(tablet) {
    	font-size: 42px;
  	}
	@include media-breakpoint-up(large) {
    	font-size: 48px;
  	}
}

.hero-intro {
	padding-bottom: 24px;
	font-size: 20px;
	line-height: 1.5;
	@include media-breakpoint-up(large) {
		font-size: 24px;
		line-height: 1.33;
	}
}

.hero-animation {
	flex: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	margin-top: 50px;
	@include media-breakpoint-up(tablet) {
		transform: scale(0.75);
		transform-origin: top;
	}
	@include media-breakpoint-up(lg) {
		transform: none;
	}
}

.hero-device {
	position: relative;
	// transition: $hero-device-delay ease transform;
	animation: heroDeviceAnimation $hero-device-delay 1 ease-out forwards;
	animation-delay: .1s;
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
	&.is-loaded {
		visibility: visible !important;
	}
}

.hero-device-desktop {
	display: none;
	transform: translate(0, 85%);
	&.is-loaded {
		// transform: translate(0, 0);
	}
	@include media-breakpoint-up(tablet) {
		display: block;
	}
}

.hero-device-mobile {
	@include media-breakpoint-up(tablet) {
		display: none;
	}
}

.hero-feature {
	position: absolute;
	z-index: 1;
	opacity: 0;
	// transition: 3s ease transform;

	object {
		max-width: 100%;
		height: auto;
	}
}

.hero-feature-1 {
	top: 20px;
	left: -35px;
	transform: translate(50px, 30px);
	z-index: 2;
	.hero-device-desktop & {
		animation: heroFeatureAnimation $hero-feature-delay 1 ease-out forwards;
		animation-delay: $hero-device-delay;
	}
}

.hero-feature-2 {
	top: -30px;
	right: -60px;
	transform: translate(-60px, 15px);
	width: 160px;
	@include media-breakpoint-up(tablet) {
		top: 35px;
		width: auto;
	}
	@media (min-width: 1140px) {
		right: -120px;
	}
	.hero-device-desktop &,
	[data-current="00"] ~ &, // initial state
	[data-current="0"] ~ & {
		animation: heroFeatureAnimation $hero-feature-delay 1 ease-out forwards;
	}
	.hero-device-desktop & {
		animation-delay: $hero-device-delay + $hero-feature-delay;
	}
	[data-current="1"] ~ & {
		animation: heroFeatureFadeOut $hero-feature-delay * 2 1 ease-out forwards;
	}
}

.hero-feature-3 {
	bottom: 40px;
	left: -70px;
	width: 140px;
	transform: translate(70px, 10px);
	@include media-breakpoint-up(tablet) {
		top: 65px;
		bottom: auto;
		width: auto;
	}
	@media (min-width: 1140px) {
		left: -150px;
	}
	[data-current="1"] ~ &,
	.hero-device-desktop & {
		animation: heroFeatureAnimation $hero-feature-delay 1 ease-out forwards;
	}
	.hero-device-desktop & {
		animation-delay: $hero-device-delay + $hero-feature-delay * 2;
	}
	[data-current="2"] ~ & {
		animation: heroFeatureFadeOut $hero-feature-delay * 2 1 ease-out forwards;
	}
}

.hero-feature-4 {
	top: 80px;
	right: -60px;
	transform: translate(80px, -20px);
	width: 120px;
	z-index: 2;
	@include media-breakpoint-up(tablet) {
		top: 200px;
		right: auto;
		left: -77px;
		width: auto;
	}
	[data-current="3"] ~ &,
	.hero-device-desktop & {
		animation: heroFeatureAnimation $hero-feature-delay 1 ease-out forwards;
	}
	.hero-device-desktop & {
		animation-delay: $hero-device-delay + $hero-feature-delay * 3;
	}
	[data-current="0"] ~ & {
		animation: heroFeatureFadeOut $hero-feature-delay * 2 1 ease-out forwards;
	}
}

.hero-feature-5 {
	bottom: 40%;
	left: -60px;
	width: 170px;
	transform: translate(40px, -100px);
	@include media-breakpoint-up(tablet) {
		bottom: 0;
		width: auto;
	}
	@media (min-width: 1140px) {
		left: -155px;
	}
	[data-current="2"] ~ &,
	.hero-device-desktop & {
		animation: heroFeatureAnimation $hero-feature-delay 1 ease-out forwards;
	}
	.hero-device-desktop & {
		animation-delay: $hero-device-delay + $hero-feature-delay * 4;
	}
	[data-current="3"] ~ & {
		animation: heroFeatureFadeOut $hero-feature-delay * 2 1 ease-out forwards;
	}
}

.hero-carousel {
	position: absolute !important;
	top: 50px;
	left: 50%;
	width: 220px;
	transform: translate(-50%, 0);
	@include media-breakpoint-up(tablet) {
		display: none;
	}
}


@keyframes heroTextAnimation {
  60% {
    transform: translate(0, 5px);
    visibility: visible;
  }
  100% {
  	transform: translate(0, 0);
    visibility: visible;
  }
}

@keyframes heroDeviceAnimation {
  60% {
    transform: translate(0, -5px);
    visibility: visible;
  }
  100% {
  	transform: translate(0, 0);
    visibility: visible;
  }
}


@keyframes heroFeatureAnimation {
  to {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes heroFeatureFadeOut {
  0% {
  	transform: translate(0, 0);
	visibility: visible;
	opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
