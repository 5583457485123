@use "sass:math";

.header {
    position: relative;
    flex: none;
    display: flex;
    height: $header-home-height-mobile;
    border-bottom: 1px solid $gray-base-70;
    background-color: #fff;
    z-index: 11;
    @include media-breakpoint-up(lg) {
        height: $header-home-height;
    }
}

.header-container {
    display: flex;
    flex-wrap: wrap;
    padding: 15px $grid-gutter-width;
    max-width: map-get($grid-breakpoints, xl);
    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }
    @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width;
    }
}

.header-logo-wrap {
    display: flex;
    align-items: center;
    width: 150px;
    min-width: 150px;
    margin-right: 24px;
    @include media-breakpoint-up(large) {
        align-items: stretch;
        width: auto;
        margin-right: 69px;
    }
}

.header-logo {
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        max-width: 220px;
    }
    @include header-focus();
    &:before {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        @include media-breakpoint-up(lg) {
            height: calc(100% - 4px);
        }
    }

    img {
        display: block;
        width: 100%;
    }
}


// MENUS

.header-mainmenu .dropdown,
.header-sidemenu .dropdown {
    &:hover,
    &:focus,
    &:active {
        .dropdown-menu {
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }

    .dropdown-menu {
        min-width: 275px;
        box-shadow: 0 12px 24px rgba(15,25,40,.04);
    }

    .dropdown-item {
        padding: 12px 0;
        font-size: 17px;
        font-weight: 700;
        &:hover,
        &:focus,
        &:active {
            background-color: transparent;
            color: $yc-blue;
        }
        @include media-breakpoint-up(lg) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}


// MAIN MENU

.header-mainmenu {
    flex: auto;
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
    }

    .dropdown-menu {
        .header-container {
            padding-bottom: 20px;
        }

        .header-logo-wrap {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        .row {
            flex: auto;
            display: block;
            column-count: 2;
            padding-left: 20px;
            padding-right: 20px;
            @include media-breakpoint-up(lg) {
                display: flex;
            }
            @include media-breakpoint-up(lg) {
                padding-left: $spacer * 2;
                padding-right: $spacer * 2;
            }
        }

        .col {
            flex-basis: 100%;
            display: inline-block;
            width: 100%;
            @include media-breakpoint-up(lg) {
                flex: 0 0 161px;
            }
        }
    }

    &.show {
        & > .dropdown-toggle {
            background-color: transparent;
        }
        // mobile styles
        @include media-breakpoint-down(tablet) {
            position: absolute;
            top: calc(100% + 1px);
            left: 0;
            width: 100%;
            display: flex;
            flex: 0 0 100%;
            flex-direction: column;
            height: calc(100vh - $header-home-height-mobile);
            padding: $grid-gutter-width;
            background-color: #fff;
            overflow: scroll;
            z-index: 100;

            .header-mainmenu-item {
                flex-direction: column;

                &.dropdown.show {
                    .header-mainmenu-link {
                        background-color: transparent;
                    }
                }

                &:not(:last-child) {
                    // border-bottom: 1px solid $gray-base-50;
                }

                .dropdown-menu {
                    position: static;
                    margin-top: 0;
                    padding-left: 20px;
                    padding-top: 0;
                    padding-bottom: 0;
                    border: 0;
                    box-shadow: none;
                }
            }

            .header-mainmenu-link {
                position: relative;
                width: 100%;
                padding-top: 12px;
                padding-bottom: 12px;
                justify-content: flex-start;
                // color: #fff;
                &.active {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
                // &:active {
                //     &:before {
                //         position: absolute;
                //         top: 0;
                //         left: 0;
                //         width: 100%;
                //         height: 100%;
                //         display: block;
                //         // background-color: ;
                //         content: "";
                //     }
                // }
            }

            .dropdown-item {
                font-size: 17px;
                text-transform: uppercase;
            }
        }
        .header-dropdown-toggle {
            // Generate the caret automatically
            &:after {
                @include caret;
            }
        }
    }
}

.header-mainmenu-item {
    // position: static;
    display: flex;
    &:hover {
        // background-color: $header-color-dark;
    }

    &:hover {
        .header-mainmenu-link {
            color: $yc-blue;
        }
    }

    .dropdown-item {
        padding-right: 15px;
        padding-left: 15px;
        transition: .2s ease all;
    }
}

.header-mainmenu-link {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    color: #000;
    text-transform: uppercase;
    transition: .2s ease all;
    cursor: pointer;
    &.dropdown-toggle:after {
        @include media-breakpoint-up(lg) {
            content: none;
        }
    }
    &:hover,
    &:focus,
    &:active {
        color: $yc-blue;
    }

    @include media-breakpoint-up(lg) {
        padding-right: 30px;
        font-size: 15px;
    }
    @include media-breakpoint-up(large) {
        font-size: 17px;
    }
    @include header-focus();
}


// SIDE MENU

.header-sidemenu {
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 0;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        margin-left: auto;
    }

    .y-btn {
        min-height: 44px;
        font-size: 17px;
        text-transform: uppercase;
        justify-content: center;
        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
            font-size: 15px;
        }
        @include media-breakpoint-up(large) {
            font-size: 17px;
        }
    }
}

.sidemenu-item {
    flex: none;
    display: flex;
    min-width: 0;
    margin-top: 24px;
    @include header-focus();
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
    &:not(:first-child) {
        margin-top: 24px;
        @include media-breakpoint-up(lg) {
            margin-left: 24px;
            margin-top: 0;
        }
    }
}

.sidemenu-item-text {
    display: flex;
    align-items: center;
    font-weight: 700;
}

.sidemenu-item-link {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: $header-color;
    &:hover,
    &:focus,
    &:active {
        color: #fff;
    }
}

// HAMBURGER

.header-toggler {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.header-mainmenu-toggler-button {
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    @include header-focus();
    &:before {
        width: calc(100% + 20px);
        height: calc(100% + 12px);
    }

    .line{
        width: 26px;
        height: 4px;
        background-color: $header-color-medium;
        display: block;
        margin: 5px auto;
        transition: all .3s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
    }

    &.is-active .line {
        // background-color: #fff;
        &:nth-child(2) {
            opacity: 0;
        }
        &:nth-child(1) {
            transform: translateY(9px) rotate(45deg);
        }
        &:nth-child(3) {
            transform: translateY(-9px) rotate(-45deg);
        }
    }
}
