
.app-on-scroll {
  opacity: 0;
  transform: translate(0, 20px);

  .is-visible & {
    animation: sectionPartAnimation .75s 1 ease-out forwards;
  }
}

.delay-1 {
  animation-delay: $hero-feature-delay !important;
}

@keyframes sectionPartAnimation {
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}


.absolute {
 position: absolute;
}

.relative {
 position: relative;
}

.inset-0 {
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
}

.top-3\/4 {
 top: 75%;
}

.left-\[-300px\] {
 left: -300px;
}

.top-0 {
 top: 0;
}

.bottom-\[-250px\] {
 bottom: -250px;
}

.right-\[-300px\] {
 right: -300px;
}

.left-0 {
 left: 0;
}

.top-\[370px\] {
 top: 370px;
}

.left-\[-280px\] {
 left: -280px;
}

.top-\[213px\] {
 top: 213px;
}

.right-\[50px\] {
 right: 50px;
}

.bottom-0 {
 bottom: 0;
}

.left-1\/2 {
 left: 50%;
}

.left-full {
 left: 100%;
}

.right-full {
 right: 100%;
}

.top-1em {
 top: 1em;
}

.top-\[400px\] {
 top: 400px;
}

.right-\[-400px\] {
 right: -400px;
}

.top-\[30\%\] {
 top: 30%;
}

.left-\[-55px\] {
 left: -55px;
}

.top-\[55\%\] {
 top: 55%;
}

.left-\[50\%\] {
 left: 50%;
}

.bottom-\[-200px\] {
 bottom: -200px;
}

.right-\[-200px\] {
 right: -200px;
}

.left-\[80\%\] {
 left: 80%;
}

.top-\[50\%\] {
 top: 50%;
}

.left-\[850px\] {
 left: 850px;
}

.right-\[850px\] {
 right: 850px;
}

.z-1 {
 z-index: 1;
}

.-z-1 {
 z-index: -1;
}

.col-span-5 {
 grid-column: span 5 / span 5;
}

.col-span-10 {
 grid-column: span 10 / span 10;
}

.col-span-1 {
 grid-column: span 1 / span 1;
}

.col-span-12 {
 grid-column: span 12 / span 12;
}

.col-span-2 {
 grid-column: span 2 / span 2;
}

.col-span-4 {
 grid-column: span 4 / span 4;
}

.row-span-2 {
 grid-row: span 2 / span 2;
}

.mx-auto {
 margin-left: auto;
 margin-right: auto;
}

.ml-grid-half {
 margin-left: 10px;
}

.mb-grid-half {
 margin-bottom: 10px;
}

.mb-40px {
 margin-bottom: 40px;
}

.mb-grid {
 margin-bottom: 20px;
}

.mt-auto {
 margin-top: auto;
}

.mb-\[36px\] {
 margin-bottom: 36px;
}

.mb-\[80px\] {
 margin-bottom: 80px;
}

.ml-1em {
 margin-left: 1em;
}

.mr-1em {
 margin-right: 1em;
}

.ml-grid {
 margin-left: 20px;
}

.mb-32px {
 margin-bottom: 32px;
}

.mb-0 {
 margin-bottom: 0;
}

.mr-12px {
 margin-right: 12px;
}

.mr-auto {
 margin-right: auto;
}

.mb-16px {
 margin-bottom: 16px;
}

.block {
 display: block;
}

.inline-block {
 display: inline-block;
}

.flex {
 display: flex;
}

.grid {
 display: grid;
}

.hidden {
 display: none;
}

.h-80px {
 height: 80px;
}

.h-full {
 height: 100%;
}

.h-\[56px\] {
 height: 56px;
}

.h-\[300px\] {
 height: 300px;
}

.h-12px {
 height: 12px;
}

.h-\[408px\] {
 height: 408px;
}

.h-\[68px\] {
 height: 68px;
}

.min-h-\[280px\] {
 min-height: 280px;
}

.w-full {
 width: 100%;
}

.w-80px {
 width: 80px;
}

.w-120px {
 width: 120px;
}

.w-\[56px\] {
 width: 56px;
}

.w-\[150px\] {
 width: 150px;
}

.w-\[68px\] {
 width: 68px;
}

.w-\[50vw\] {
 width: 50vw;
}

.flex-none {
 flex: none;
}

.translate-x-\[25\%\] {
 transform: translate(25%, 0);
}

.grid-cols-11 {
 grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-10 {
 grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-12 {
 grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
 grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-4 {
 grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-1 {
 grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
 flex-direction: column;
}

.items-end {
 align-items: flex-end;
}

.items-center {
 align-items: center;
}

.items-baseline {
 align-items: baseline;
}

.justify-center {
 justify-content: center;
}

.justify-between {
 justify-content: space-between;
}

.gap-grid {
 gap: 20px;
}

.gap-40px {
 gap: 40px;
}

.gap-x-grid {
 -moz-column-gap: 20px;
      column-gap: 20px;
}

.self-end {
 align-self: flex-end;
}

.self-center {
 align-self: center;
}
.justify-evenly {
  justify-content: space-evenly;
}

.overflow-hidden {
 overflow: hidden;
}

.whitespace-nowrap {
 white-space: nowrap;
}

.rounded-full {
 border-radius: 9999px;
}

.border-l-2 {
 border-left-width: 2px;
}

.border-t-2 {
 border-top-width: 2px;
}

.bg-primary {
 --tw-bg-opacity: 1;
 background-color: rgb(2 115 207 / var(--tw-bg-opacity));
}

.bg-white {
 --tw-bg-opacity: 1;
 background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-t {
 background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-white {
 --tw-gradient-from: #fff;
 --tw-gradient-to: rgb(255 255 255 / 0);
 --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-white {
 --tw-gradient-to: rgb(255 255 255 / 0);
 --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to);
}

.object-center {
 -o-object-position: center;
    object-position: center;
}

.p-\[6px\] {
 padding: 6px;
}

.p-\[4px\] {
 padding: 4px;
}

.px-grid {
 padding-left: 20px;
 padding-right: 20px;
}

.py-32px {
 padding-top: 32px;
 padding-bottom: 32px;
}

.py-grid {
 padding-top: 20px;
 padding-bottom: 20px;
}

.pb-\[60px\] {
 padding-bottom: 60px;
}

.pt-32px {
 padding-top: 32px;
}

.pb-\[100px\] {
 padding-bottom: 100px;
}

.pt-40px {
 padding-top: 40px;
}

.pl-grid {
 padding-left: 20px;
}

.pl-36px {
  padding-left: 36px;
 }
 
.pb-40px {
 padding-bottom: 40px;
}

.pr-grid {
 padding-right: 20px;
}

.pt-\[50px\] {
 padding-top: 50px;
}

.pb-24px {
 padding-bottom: 24px;
}

.pt-grid {
 padding-top: 20px;
}

.pt-64px {
 padding-top: 64px;
}

.pb-32px {
 padding-bottom: 32px;
}

.space-y-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.text-center {
  text-align: center;
}

.w-32 {
  width: 32px;
}

.h-32 {
  height: 32px;
}

.w-24 {
  width: 24px;
}

.h-24 {
  height: 24px;
}

@include media-breakpoint-up(tablet) {

 .md\:inline-block{
  display: inline-block;
 }

 .md\:left-\[40\%\]{
  left: 40%;
 }

 .md\:col-span-6{
  grid-column: span 6 / span 6;
 }

 .md\:col-span-7{
  grid-column: span 7 / span 7;
 }

 .md\:col-span-3{
  grid-column: span 3 / span 3;
 }

 .md\:col-start-8{
  grid-column-start: 8;
 }

 .md\:row-span-2{
  grid-row: span 2 / span 2;
 }

 .md\:row-span-3{
  grid-row: span 3 / span 3;
 }

 .md\:row-start-1{
  grid-row-start: 1;
 }

 .md\:row-start-2{
  grid-row-start: 2;
 }

 .md\:row-start-3{
  grid-row-start: 3;
 }

 .md\:mx-1\/12{
  margin-left: calc(100%/12);
  margin-right: calc(100%/12);
 }

 .md\:mx-auto{
  margin-left: auto;
  margin-right: auto;
 }

 .md\:ml-1\/12{
  margin-left: calc(100%/12);
 }

 .md\:mt-32px{
  margin-top: 32px;
 }

 .md\:mb-0{
  margin-bottom: 0;
 }

 .md\:m-0{
  margin: 0;
 }

 .md\:ml-\[56px\]{
  margin-left: 56px;
 }

 .md\:mb-32px{
  margin-bottom: 32px;
 }

 .md\:ml-grid{
  margin-left: 20px;
 }

 .md\:mr-grid{
  margin-right: 20px;
 }

 .md\:mr-40px{
  margin-right: 40px;
 }

 .md\:mr-3{
  margin-right: 0.75rem;
 }

 .md\:block{
  display: block;
 }

 .md\:grid{
  display: grid;
 }

 .md\:hidden{
  display: none;
 }

 .md\:h-\[130px\]{
  height: 130px;
 }

 .md\:h-\[76px\]{
  height: 76px;
 }

 .md\:w-\[103\%\]{
  width: 103%;
 }

 .md\:w-\[130px\]{
  width: 130px;
 }

 .md\:w-\[210px\]{
  width: 210px;
 }

 .md\:w-\[76px\]{
  width: 76px;
 }

 .md\:w-1\/4{
  width: 25%;
 }

 .md\:w-1\/2{
  width: 50%;
 }

 .md\:w-1\/3{
  width: 33.333332%;
 }

 .md\:w-2\/3{
  width: 66.666667%;
 }

 .md\:w-\[56px\]{
  width: 56px;
 }

 .md\:max-w-\[103\%\]{
  max-width: 103%;
 }

 .md\:max-w-\[430px\]{
  max-width: 430px;
 }

 .md\:grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
 }

 .md\:grid-cols-10{
  grid-template-columns: repeat(10, minmax(0, 1fr));
 }

 .md\:col-span-6{
  grid-column: span 6 / span 6;
 }

 .md\:col-span-3{
  grid-column: span 3 / span 3;
 }

 .md\:col-start-8{
  grid-column-start: 8;
 }

 .md\:flex-row{
  flex-direction: row;
 }

 .md\:flex-col{
  flex-direction: column;
 }

 .md\:items-center{
  align-items: center;
 }

 .md\:justify-evenly{
  justify-content: space-evenly;
 }

 .md\:self-start{
  align-self: flex-start;
 }

 .md\:justify-start {
  justify-content: flex-start;
  } 
 
 .md\:items-stretch {
  align-items: stretch;
 }

 .md\:px-0{
  padding-left: 0;
  padding-right: 0;
 }

 .md\:py-\[60px\]{
  padding-top: 60px;
  padding-bottom: 60px;
 }

 .md\:py-80px{
  padding-top: 80px;
  padding-bottom: 80px;
 }

 .md\:py-\[90px\]{
  padding-top: 90px;
  padding-bottom: 90px;
 }

 .md\:pt-\[40px\]{
  padding-top: 40px;
 }

 .md\:pb-\[50px\]{
  padding-bottom: 50px;
 }

 .md\:pt-\[80px\]{
  padding-top: 80px;
 }

 .md\:pb-\[90px\]{
  padding-bottom: 90px;
 }

 .md\:pl-\[48px\]{
  padding-left: 48px;
 }

 .md\:pt-0{
  padding-top: 0;
 }

 .md\:pl-0{
  padding-left: 0;
 }

 .md\:pb-\[120px\]{
  padding-bottom: 120px;
 }

 .md\:pb-\[70px\]{
  padding-bottom: 70px;
 }

 .md\:space-y-0{
  padding-left: 0;
  padding-right: 0;
 }

 .md\:pr-10 {
  padding-right: 2.5rem;
 }

 .md\:text-left{
  text-align: left;
 }

 .md\:pointer-events-none{
  pointer-events: none;
 }
}

@include media-breakpoint-up(lg) {

   .lg\:col-span-6{
  grid-column: span 6 / span 6;
 }

 .lg\:col-span-5{
  grid-column: span 5 / span 5;
 }

 .lg\:row-span-3{
  grid-row: span 3 / span 3;
 }

 .lg\:mx-1\/12{
  margin-left: calc(100%/12);
  margin-right: calc(100%/12);
 }

 .lg\:ml-1\/6{
  margin-left: calc(100%/6);
 }

 .lg\:block{
  display: block;
 }

 .lg\:px-0{
  padding-left: 0;
  padding-right: 0;
 }

 .lg\:px-40px{
  padding-left: 40px;
  padding-right: 40px;
 }

 .lg\:pb-\[140px\]{
  padding-bottom: 140px;
 }

 .lg\:pr-20 {
  padding-right: 5rem;
 }

 .lg\:pl-36px {
  padding-left: 36px;
 }
 
}@media (min-width: 1280px){

   .xl\:left-grid{
  left: 20px;
 }

 .xl\:left-80px{
  left: 80px;
 }

 .xl\:top-\[-42px\]{
  top: -42px;
 }

 .xl\:col-span-8{
  grid-column: span 8 / span 8;
 }

 .xl\:col-span-2{
  grid-column: span 2 / span 2;
 }

 .xl\:col-span-4{
  grid-column: span 4 / span 4;
 }

 .xl\:col-span-3{
  grid-column: span 3 / span 3;
 }

 .xl\:col-span-1{
  grid-column: span 1 / span 1;
 }

 .xl\:col-start-9{
  grid-column-start: 9;
 }

 .xl\:row-span-2{
  grid-row: span 2 / span 2;
 }

 .xl\:float-right{
  float: right;
 }

 .xl\:mx-1\/12{
  margin-left: calc(100%/12);
  margin-right: calc(100%/12);
 }

 .xl\:mt-32px{
  margin-top: 32px;
 }

 .xl\:mb-0{
  margin-bottom: 0;
 }

 .xl\:mb-\[56px\]{
  margin-bottom: 56px;
 }

 .xl\:mr-1\/12{
  margin-right: calc(100%/12);
 }

 .xl\:mr-40px{
  margin-right: 40px;
 }

 .xl\:block{
  display: block;
 }

 .xl\:hidden{
  display: none;
 }

 .xl\:h-\[164px\]{
  height: 164px;
 }

 .xl\:h-\[210px\]{
  height: 210px;
 }

 .xl\:h-\[56px\]{
  height: 56px;
 }

 .xl\:h-\[68px\]{
  height: 68px;
 }

 .xl\:w-\[164px\]{
  width: 164px;
 }

 .xl\:w-\[210px\]{
  width: 210px;
 }

 .xl\:w-\[115\%\]{
  width: 115%;
 }

 .xl\:w-\[56px\]{
  width: 56px;
 }

 .xl\:w-\[68px\]{
  width: 68px;
 }

 .xl\:max-w-none{
  max-width: none;
 }

 .xl\:grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
 }

 .xl\:grid-rows-goal{
  grid-template-rows: auto auto 1fr;
 }

 .xl\:self-center{
  align-self: center;
 }

 .xl\:py-\[150px\]{
  padding-top: 150px;
  padding-bottom: 150px;
 }

 .xl\:px-grid{
  padding-left: 20px;
  padding-right: 20px;
 }

 .xl\:py-80px{
  padding-top: 80px;
  padding-bottom: 80px;
 }

 .xl\:pl-grid{
  padding-left: 20px;
 }

 .xl\:pr-40px{
  padding-right: 40px;
 }

 .xl\:pt-\[90px\]{
  padding-top: 90px;
 }

 .xl\:pb-\[120px\]{
  padding-bottom: 120px;
 }

 .xl\:pb-0{
  padding-bottom: 0;
 }

 .xl\:text-left{
  text-align: left;
 }
}

@media (min-width: 1440px){.\32xl\:container{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
 }@media (min-width: 640px){.\32xl\:container{
   max-width: 640px;
  }
 }@media (min-width: 768px){.\32xl\:container{
   max-width: 768px;
  }
 }@media (min-width: 1024px){.\32xl\:container{
   max-width: 1024px;
  }
 }@media (min-width: 1280px){.\32xl\:container{
   max-width: 1280px;
  }
 }@media (min-width: 1440px){.\32xl\:container{
   max-width: 1440px;
  }
 }
}

.border-24 {
  border-width: 24px;
  border-style: solid;
}

.border-color-white {
  border-color: #fff;
}