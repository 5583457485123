
body {
  -webkit-font-smoothing: antialiased;
  font-family: Archivo, sans-serif;
}

.header {
  font-family: "Roboto Condensed";
}

p {
  font-size: 16px;
  line-height: 28px;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.heading-1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -.02em;
  @media (min-width: 1280px) {
    font-size: 58px;
  }
}

.heading-1a {
 font-size: 30px;
 font-weight: 700;
 line-height: 1.25;
}

@include media-breakpoint-up(tablet) {
 .heading-1a {
  font-size: 36px;
 }
}

@media (min-width: 1280px) {
 .heading-1a {
  font-size: 60px;
 }
}

.heading-2 {

 font-size: 36px;
 font-weight: 600;
 line-height: 1.25;
 letter-spacing: -.02em;
}

@media (min-width: 1280px) {
 .heading-2 {

 font-size: 48px;
 }


}

.heading-2a {

 font-size: 36px;
 font-weight: 600;
 line-height: 1.25;
 letter-spacing: -.02em;
}

@media (min-width: 1280px) {
 .heading-2a {

 font-size: 48px;
 }


}

.heading-2b {

 font-size: 30px;
 font-weight: 700;
 line-height: 1.25;
 letter-spacing: -.02em;
}

@include media-breakpoint-up(tablet) {
 .heading-2b {

 font-size: 36px;
 }


}

@media (min-width: 1280px) {
 .heading-2b {

 font-size: 48px;
 }


}

.heading-3 {

 font-size: 26px;
 font-weight: 700;
 line-height: 1.25;
}

@include media-breakpoint-up(tablet) {
 .heading-3 {

 font-size: 30px;
 }


}

@media (min-width: 1280px) {
 .heading-3 {

 font-size: 26px;
 }


}

.heading-4 {

 font-size: 24px;
 font-weight: 700;
 line-height: 1.35; /* blog cards */
}

.heading-5 {

 font-size: 20px;
 font-weight: 700;
 line-height: 1.5;
}

.heading-quiz {

 font-family: "Roboto Condensed";
 font-size: 20px;
 font-weight: 700;
 line-height: 1.35;
}

@include media-breakpoint-up(tablet) {
 .heading-quiz {

 font-size: 24px;
 }


}

.article-1 {

 font-size: 20px;
 font-weight: 400;
 line-height: 1.5;
 letter-spacing: -.02em;
}

@include media-breakpoint-up(tablet) {
 .article-1 {

 font-size: 24px;
 line-height: 1.5833;
 }


}

@media (min-width: 1280px) {
 .article-1 {

 font-size: 32px;
 line-height: 1.5;
 }


}

.article-1 {
 /* quotation */
	}

 .article-2 {

 font-size: 18px;
 line-height: 1.5;
}

@include media-breakpoint-up(tablet) {
 .article-2 {

 font-size: 14px;
 line-height: 1.25;
 }


}

@media (min-width: 1280px) {
 .article-2 {

 font-size: 24px;
 line-height: 1.35;
 }


}

.article-2 {
 /* hero intro */
	}

 .article-3 {

 font-size: 16px;
 font-weight: 600;
 line-height: 1.5;
}

@include media-breakpoint-up(tablet) {
 .article-3 {

 font-size: 20px;
 }


}

@media (min-width: 1280px) {
 .article-3 {

 font-size: 24px;
 }


}

.article-20 {

 font-size: 20px;
 font-weight: 500;
 line-height: 1.5;
 letter-spacing: -.02em;
}

.article-16 {

 font-size: 16px;
 font-weight: 400;
 line-height: 1.5;
}

.quote-symbol {

 font-size: 20px;
}

@include media-breakpoint-up(tablet) {
 .quote-symbol {

 font-size: 80px;
 line-height: 1;
 }


}

.quote-author {

 font-family: "Roboto Condensed";
 font-size: 16px;
 font-weight: 700;
 line-height: 1.5833;
}

@include media-breakpoint-up(tablet) {
 .quote-author {

 font-size: 20px;
 }


}

@media (min-width: 1280px) {
 .quote-author {

 font-size: 20px;
 }


}

.quotes .article-1:after {
  content: "”";
}

.doc-symbol {

 font-family: "Roboto Condensed";
 font-size: 16px;
 font-weight: 700;
 line-height: 1;
}

.font-semibold {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}