.card {
 display: flex;
 flex-direction: column;
 padding: 16px;
 background-color: #fff;
 color: $gray-base-10;
 transition: $home-transition;

&:hover {
 box-shadow: $home-card-box-shadow;
}
}

.card-image {
 margin-bottom: 8px;
}

.card-tagline {
 margin-bottom: 8px;
}

.card-date {
 display: inline-block;
 font-size: 14px;
 font-weight: 600;
 line-height: 1.5;
 color: $gray-base-30;
}

.card-title {
 margin-bottom: 8px;
}

.card-body {
 margin-bottom: auto;
}

@media (min-width: 1280px) {.card-body {
  min-height: 115px;
 }


}

.card-footer {
 margin-top: 20px;
}

.tag {
 display: inline-block;
 padding-left: 12px;
 padding-right: 12px;
 padding-top: 4px;
 padding-bottom: 4px;
 font-size: 14px;
 font-weight: 600;
 line-height: 1.5;
}

.card-quiz {
  display: inline-flex;
  min-height: 85px;
  align-items: center;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
  border: 2px solid $gray-base-90;
  color: $gray-base-20;
  transition: $home-transition;
}

@media (min-width: 1280px) {
  .card-quiz {
    min-height: 264px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

.card-quiz:hover {
 border-color: $yc-blue-ada;
 background-color: $yc-blue-95;
 box-shadow: $home-card-box-shadow;
}

.card-quiz {
 cursor: pointer;
 border-width: 2px;
 border-color: $gray-base-90;
}

.card-quiz-icon {
 margin-right: 32px;
 height: 34px;
 width: 34px;
 flex: none;
}

@media (min-width: 1280px) {.card-quiz-icon {
  margin-right: 0;
  margin-bottom: 40px;
  height: 64px;
  width: 64px;
 }


}

.card-info {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-base-80;
  background-color: #fff;
  padding: 16px;
  transition: $home-transition;
  &:hover {
    border-color: $yc-blue-ada;
    box-shadow: $home-card-box-shadow;
    outline: 1px solid $yc-blue-ada;
    color: #000;
  }
  &:focus {
    background-color: $yc-blue-95;
    outline: 1px solid $yc-blue-ada;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
.icon svg  {
		width: 1em;
		height: 1em;
	}

.angle-right:after {
  margin-left: 10px;
  display: inline;
  height: 16px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
  background: transparent center no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='9' height='16'><path fill='%23000' d='M9 8 0 .5V4l4.5 4L0 11.5v4L9 8Z'/></svg>");
  content: "";
}

