
.gradient-hero-1 {
	width: 727.16px;
	height: 489.33px;
	background: linear-gradient(151.06deg, #C0DCF3 20.7%, rgba(192, 220, 243, 0) 68.3%);
	filter: blur(59px);
	transform: rotate(-26.98deg);
}

.gradient-hero-2 {
	width: 811.56px;
	height: 546.13px;
	background: linear-gradient(151.06deg, rgba(209, 183, 255, 0.42) 20.7%, rgba(105, 51, 201, 0.75) 68.3%);
	opacity: 0.5;
	filter: blur(59px);
	transform: rotate(-26.98deg);

}

.gradient-stream-1 {
	width: 706.89px;
	height: 408.73px;
	background: rgba(65, 161, 237, 0.4);
	filter: blur(100px);
	transform: rotate(106.23deg);
}

.gradient-stream-2 {
	width: 624.79px;
	height: 391.5px;
	background: rgba(244, 171, 121, 0.35);
	filter: blur(100px);
	transform: rotate(106.23deg);
}

.gradient-goals-1 {
	width: 833.51px;
	height: 481.94px;
	background: rgba(65, 161, 237, 0.4);
	filter: blur(100px);
	transform: rotate(106.23deg);
}

.gradient-goals-2 {
	width: 457.67px;
	height: 603.75px;
	background: rgba(255, 198, 161, 0.22);
	filter: blur(59px);
	transform: rotate(20deg);
}

.gradient-goals-3 {
	width: 376.11px;
	height: 264.8px;
	background: #E1DAEE;
	filter: blur(82px);
	transform: rotate(106.23deg);
}

.gradient-goals-4 {
	width: 575.07px;
	height: 327.73px;
	background: rgba(65, 161, 237, 0.4);
	filter: blur(100px);
	transform: rotate(106.23deg);
}

.gradient-quiz {
	background: linear-gradient(270deg, #6933C9 0.21%, #80C0F3 73.35%);
}

.gradient-cta {
	background: linear-gradient(135.91deg, #41A1ED 3.02%, rgba(65, 161, 237, 0) 89.54%), #0273CF;
}

.gradient-video {
	width: 833.273px;
	height: 585.825px;
	background: rgba(134, 85, 211, 0.16);
	filter: blur(106.5px);
	transform: rotate(135.003deg);
}

.gradient-blog {
	width: 833.51px;
	height: 481.94px;
	background: rgba(65, 161, 237, 0.4);
	filter: blur(100px);
	transform: rotate(106.23deg);
}

.gradient-request {
	position: absolute;
	width: 770.9px;
	height: 445.74px;
	left: -78.3px;
	bottom: -473.43px;
	background: rgba(65, 161, 237, 0.4);
	filter: blur(100px);
	// transform: matrix(0.96, -0.28, -0.28, -0.96, 0, 0);
	transform: rotate(106.23deg);
}

.gradient-request-sm {
	position: absolute;
	left: 17.89%;
	right: 9.21%;
	top: 58.95%;
	bottom: 14.24%;
	background: rgba(65, 161, 237, 0.4);
	filter: blur(100px);
	transform: matrix(0.96, -0.28, -0.28, -0.96, 0, 0);
}

.dash-horizontal {
	border-top-style: solid;
	border-image: repeating-linear-gradient(90deg, black, black 4%, transparent 4%, transparent 10%) 4;
}

.dash-vertical {
	border-left-style: solid;
	border-image: repeating-linear-gradient(0, black, black 2%, transparent 2%, transparent 6%) 4;
}

.dash-vertical:before{
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
	height: 10px;
	width: 10px;
	border-right-width: 2px;
	border-bottom-width: 2px;
	border-right-style: solid;
	border-bottom-style: solid;
	--tw-border-opacity: 1;
	border-color: #000;
	transform: rotate(45deg) translate(-4px, 4px);
	content: "";
}

.shadow-image{
 --tw-shadow: 3px 3px 11px rgba(0, 0, 0, 0.25);
 --tw-shadow-colored: 3px 3px 11px var(--tw-shadow-color);
 box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
